import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from 'react';
import { LanguageContext } from '../../../utils/LanguageContext';
import Dash from '../Dash/Dash';
import GradientBulletPoint from '../GradientBulletPoint/GradientBulletPoint';
import * as styles from './Locations.module.scss';

const c = classNames.bind(styles);

function Locations({ className, items }) {
	const language = useContext(LanguageContext);
	const query  = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						offices_title {
							en
							fr
						}
					}
				}
			}
		}
	`)
	const strings = query.wp.sp.strings.offices_title;
	return (
		<section className={c('wrapper', className)}>
			<h2 className={c('label')}>
				{
					language.languageCode === "FR" 
						? strings.fr
						: strings.en
				}
			</h2>
			<ul className={c('offices')}>
				{items.map((office, i) => (
					<li key={i} className={c('office')}>
						<GradientBulletPoint color="orange" className={c('bulletPoint')} />
						<span className={c('country')}>{office.country}</span>
						<p
							className={c('address')}
							dangerouslySetInnerHTML={{ __html: office.address }}
						/>
						<Dash className={c('dash')} />
					</li>
				))}
			</ul>
		</section>
	);
}

export default Locations;
