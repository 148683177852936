import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import Locations from '../../components/common/Locations/Locations';
import Form from '../../components/Contact/Form/Form';
import Intro from '../../components/Intro/Intro';
import Seo from '../../components/Seo/Seo';
import * as styles from './Contact.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
	query($id: String!) {
		page: wpPage(id: { eq: $id }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfPageContact {
				header {
					bgtype
					description
					image {
						url
						title
					}
					image_mobile {
							title
							url
					}
					cover {
						filesize
						height
						mime_type
						title
						url
						width
					}
					vimeoId: popin_video
					title
					video {
						url
						type
						title
					}
				}
				locations {
					items {
						address
						country
					}
				}
			}
		}
	}
`;

export default function Contact({ data }) {
	const { seo, translations, acf } = data.page;
	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro data={acf.header} />
			<Form className={c('form')} />
			<Locations items={acf.locations.items} />
		</main>
	);
}
